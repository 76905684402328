/* eslint-disable react/react-in-jsx-scope */
import 'core-js'
import 'regenerator-runtime/runtime'
import ReactDOM from 'react-dom'
import '../styles/index.css'
import '../styles/fade.css'
import { ConfigProvider, Row, Col } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import Index from '../pages/index'
import IndexM from '../pages/index.m'

ReactDOM.render(
  <ConfigProvider locale={ zhCN }>
    <Row style={{ height: '100%' }}>
      <Col style={{ height: '100%' }} span={0} lg={24}>
        <Index/>
      </Col>
      <Col style={{ height: '100%' }} span={24} lg={0}>
        <IndexM/>
      </Col>
    </Row>
  </ConfigProvider>,
  document.getElementById('root')
)
