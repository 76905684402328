// eslint-disable-next-line no-use-before-define
import React from 'react'
import css from '../styles/pages/home.module.css'
/* 组件 */
import TheHeader from '../components/TheHeader'
import TheFooter from '../components/TheFooter'
// import TheDingDingBar from '../components/TheDingDingBar'
import TheSDKTrialModal from '../components/TheSDKTrialModal'
import TheTrialMsgBtn from '../components/TheTrialMsgBtn'
import { CSSTransition } from 'react-transition-group'

// eslint-disable-next-line no-unused-vars
import { Button, Modal } from 'antd'
/* img */
const ImgPart1 = 'https://pub.wellsign.cn/website/img/home/part1.png'
const ImgPart2Back = 'https://pub.wellsign.cn/website/img/home/part2.back.png'
const ImgPart2Pros = 'https://pub.wellsign.cn/website/img/home/part2.pros.png'
const ImgPart2Play = 'https://pub.wellsign.cn/website/img/home/part4.png'
const ImgPart4Back = 'https://pub.wellsign.cn/website/img/home/part4.back.png'
const ImgPart4Back2 = 'https://pub.wellsign.cn/website/img/home/part4.back2.png'
const ImgPart4Companys = 'https://pub.wellsign.cn/website/img/home/part4.companys.png'
const ImgPart4Pros1 = 'https://pub.wellsign.cn/website/img/home/part4.pros1.png'
const ImgPart4Pros2 = 'https://pub.wellsign.cn/website/img/home/part4.pros2.png'
const ImgCard1 = 'https://pub.wellsign.cn/website/img/home.cards/1.png'
const ImgCard2 = 'https://pub.wellsign.cn/website/img/home.cards/2.png'
const ImgCard3 = 'https://pub.wellsign.cn/website/img/home.cards/3.png'
const ImgCard4 = 'https://pub.wellsign.cn/website/img/home.cards/4.png'
const ImgCard5 = 'https://pub.wellsign.cn/website/img/home.cards/5.png'
const ImgCard6 = 'https://pub.wellsign.cn/website/img/home.cards/6.png'
const ImgPart5 = 'https://pub.wellsign.cn/website/img/home/part5.png'
const ImgPlayer = 'https://pub.wellsign.cn/website/img/home/player.png'
const UrlVideo1: string = 'https://pub.wellsign.cn/website/video/home.1.mp4'
interface IPageProps {}
interface IPageState {
  scrollY: number,
  part2Fade: boolean,
  audioModalVisible: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      scrollY: -1,
      part2Fade: false,
      audioModalVisible: false
    }
  }

  componentDidMount () {
    this.setState({ scrollY: 0 })
  }

  onScroll = (a: any) => {
    const target: any = a.target
    if (this.state.scrollY < target.scrollTop) {
      this.setState({ scrollY: target.scrollTop })
    }
  }

  /* 试用 */
  private onHeaderFreeTiral = () => {
    window.location.href = './download.html'
  }

  private onPlay = () => {
    this.setState({ audioModalVisible: true })
  }

  render () {
    return <div id="index" onScroll={this.onScroll} style={{ overflowY: 'auto', height: '100%' }}>
      {/* <TheDingDingBar/> */}
      {/* 头 */}
      <TheHeader/>
      {/* part 1 */}
      <CSSTransition
        appear={ true }
        in={ true }
        timeout={2000}
        classNames="home-part1-fade">
        <div className={ css.part1 } style={{ backgroundImage: `url(${ImgPart1})` }}>
          <div className={ css.part1_title }>签字用好签</div>
          <div className={ css.part1_desc }>面向企业、个人的文件签署&管理平台</div>
          <Button
            type="primary"
            ghost
            size="large"
            style={{ width: 142, marginTop: 56, display: 'block', position: 'unset', borderRadius: 4 }}
            onClick={ this.onHeaderFreeTiral }>免费试用</Button>
        </div>
      </CSSTransition>
      {/* part2 */}
      <CSSTransition
        in={ this.state.part2Fade }
        timeout={2000}
        classNames="home-part2-fade">
        <div className={ css.part2 }>
          <img className={ css.part2_back } src={ ImgPart2Back }/>
          <div style={{ backgroundImage: `url(${ImgPart2Pros})` }}>
            <div className={ css.part2_title }>摆脱传统 便捷签署文件</div>
            <div className={ css.part2_desc }>全球500万+ 用户使用好签签署文件</div>
            <img onClick={ this.onPlay } className={ css.part2_play } style={{ pointerEvents: 'auto' }} src={ ImgPart2Play }/>
          </div>
        </div>
      </CSSTransition>
      {/* part3 */}
      <div className={ css.part3 }>
        <div>
          <div className={ css.part3_title }>为什么选择好签</div>
          <div className={ css.part3_desc }>降低成本，省时省力</div>
          <div className={ css.part3_items }>
            <div>
              <span>80%</span>
              <span>降低签署成本</span>
            </div>
            <div>
              <span>85%</span>
              <span>文件签署流程提速</span>
            </div>
            <div>
              <span>90%</span>
              <span>文件存储安全性提升</span>
            </div>
          </div>
        </div>
      </div>
      {/* part4 */}
      <div className={ css.part4 } style={{ backgroundImage: `url(${ImgPart4Back})` }}>
        <img className={ css.part4_pros1 } src={ ImgPart4Pros1 }/>
        <img className={ css.part4_pros2 } src={ ImgPart4Pros2 }/>
        <div>
          <div className={ css.part4_title }>好签如何帮助企业降本增效</div>
          <div className={ css.part4_desc }>超过10万+企业的安心选择</div>
          <div className={ css.part4_cards }>
            <div>
              <img src={ImgCard1}/>
              <span>太平洋建设</span>
              <div>建筑行业人员多、分散广，项目人员经常需要外出。纸质文件流程繁琐，处理周期长、效率低，存在代签、替签风险。使用好签在手机端即可签署文件，支持笔迹验签，安全高效。</div>
            </div>
            <div>
              <img src={ImgCard2}/>
              <span>碧桂园</span>
              <div>地产行业日常有大量文件要签字确认，且集团公司多、部门多、异地办公多，使用纸质文件时间长成本高。使用好签即可随时、随地实现移动审批签字，使用便捷，降本增效。</div>
            </div>
            <div>
              <img src={ImgCard3}/>
              <span>永辉超市</span>
              <div>零售连锁门店遍布各地，与总部签署文件困难。用好签在异地可以直接向总部提交呈批申请，大大降低沟通成本，提升了门店运营效率。同时也避免了纸质单据遗失的情况。</div>
            </div>
            <div>
              <img src={ImgCard4}/>
              <span>晋桦豹胶轮车制造</span>
              <div>制造企业为了控制风险，很多环节需要签字确认，任何环节不予及时处理都将导致工作停滞不能进行。 使用好签可以实现文件即时传输，手写签字，责任到人，提高工作效率。</div>
            </div>
            <div>
              <img src={ImgCard5}/>
              <span>国大教育集团</span>
              <div>使用好签微信快捷完成客户意向文件签署确认。高效签署，避免纸质文件并发能力弱，签署周期长，效率低，用户必须到场签署等弊端。签署更灵活，师生体验更好，签单更轻松。</div>
            </div>
            <div>
              <img src={ImgCard6}/>
              <span>迎驾酒业</span>
              <div>销售人员有频繁的出差任务，传统报销周期长，审批慢，且在外不能及时申报，个人垫资金额较大。使用好签即可手机便捷报销，审批签字高效，提升员工满意度，为企业降本增效。</div>
            </div>
          </div>
          <div className={ css.part4_companys } style={{ backgroundImage: `url(${ImgPart4Back2})` }}>
            <img src={ ImgPart4Companys } style={ { width: 929, height: 231, margin: '154px auto', display: 'block' } }/>
          </div>
        </div>
      </div>
      {/* part 5 */}
      <div className={ css.part5 }>
        <img src={ImgPart5}/>
        <div>
          <div>签字&nbsp;用好签</div>
          <Button
            size="large"
            type="default"
            ghost
            style={{ width: 140, marginTop: 38, color: '#ffffff', borderColor: '#ffffff' }}
            onClick={ this.onHeaderFreeTiral }>立即使用</Button>
        </div>
      </div>
      {/* 脚 */}
      <TheFooter/>
      {/* 试用按钮 */}
      <TheTrialMsgBtn/>
      {/* 免费使用 */}
      <TheSDKTrialModal ref={r => { this.theSDKTrialModal = r }}/>
      {/*  */}
      <Modal
        title="签字用好签"
        visible={this.state.audioModalVisible}
        footer={null}
        bodyStyle={{ padding: 10 }}
        maskClosable={ false }
        destroyOnClose={true}
        width={ 900 }
        onCancel={() => { this.setState({ audioModalVisible: false }) }}>
        {/* <img/> */}
        <video poster={ImgPlayer} controls={true} autoPlay={true} style={{ width: 880, height: 490 }} src={ this.state.audioModalVisible ? UrlVideo1 : '' }/>
      </Modal>
      {/* end */}
    </div>
  }

  private theSDKTrialModal: TheSDKTrialModal | null = null
}
