// eslint-disable-next-line no-use-before-define
import React from 'react'
import css from '../styles/pages/home.m.module.css'
/* 组件 */
import TheHeaderM from '../components/TheHeader.m'
import TheFooter from '../components/TheFooter.m'
import { Modal, message } from 'antd'
import {
  DoubleRightOutlined
} from '@ant-design/icons'
/* 图片 */
import ImgVideoPlay from '../assets/home/m.video.play.png'
import ImgVideoBack from '../assets/home/m.video.back.png'
import ImgPart31 from '../assets/home/m.part3.1.png'
import ImgPart32 from '../assets/home/m.part3.2.png'
import ImgPart33 from '../assets/home/m.part3.3.png'
import ImgPart34 from '../assets/home/m.part3.4.png'
import ImgPart35 from '../assets/home/m.part3.5.png'
import ImgPart36 from '../assets/home/m.part3.6.png'
import ImgPart5 from '../assets/home/m.part5.png'
import ImgPart6 from '../assets/home/m.part6.png'
// import TheDingDingBarM from '../components/TheDingDingBar.m'
const UrlVideo: string = 'https://pub.wellsign.cn/website/video/home.1.mp4'
/* page */
interface IPageProps {
}
interface IPageState {
  audioModalVisible: boolean,
  part3Collesped: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      audioModalVisible: false,
      part3Collesped: true
    }
  }

  componentDidMount () {
  }

  private onPlay = () => {
    this.setState({ audioModalVisible: true })
  }

  public openToApp = () => {
    const deviceType = this.detectDevice()
    if (deviceType === 'iOS') {
      if (this.isWeChatBrowser()) { // 如果是微信浏览器
        message.info('微信不支持打开此链接，前点击右上角三个点选择从浏览器点开')
        return
      }
      window.location.href = 'https://itunes.apple.com/app/apple-store/id1087399434?mt=8'
    } else if (deviceType === 'Android') {
      if (this.isWeChatBrowser()) { // 如果是微信浏览器
        message.info('微信不支持打开此链接，前点击右上角三个点选择从浏览器点开')
        return
      }
      const scheme = 'appwellsign://share/team'
      const fallbackUrl = 'https://wellsign-soft.oss-cn-beijing.aliyuncs.com/apk/wellsign.apk'
      const timeout = 3000
      const start = new Date().getTime()
      window.location.href = scheme
      setTimeout(() => {
        if (new Date().getTime() - start < timeout + 500) {
          window.location.href = fallbackUrl
        }
      }, timeout)
    } else if (deviceType === 'PC') {
      alert('当前设备不支持打开此链接，请在移动设备上打开')
    }
  }

  public isWeChatBrowser = () => {
    // 获取用户代理字符串
    const userAgent = navigator.userAgent.toLowerCase()
    // 检查是否包含微信特定的关键字
    return userAgent.includes('micromessenger')
  }

  detectDevice = () => {
    const ua = navigator.userAgent || navigator.vendor
    if (/iPad|iPhone|iPod/.test(ua)) { // 检测iOS设备
      return 'iOS'
    } else if (/android/i.test(ua)) { // 检测安卓设备
      return 'Android'
    } else { // 其他设备默认为PC
      return 'PC'
    }
  }

  render () {
    return <div
      id="IndexM"
      className={ css.page }>
      <div>
        {/* <TheDingDingBarM/> */}
        <div className= {css.headerToApp }>
          <img src="https://pub.wellsign.cn/wxopen/static/wellsign.Logo.png" alt=""></img>
          <div >签字用好签</div>
          <button onClick={ this.openToApp } >立即打开</button>
        </div>
        {/* 头 */}
        <TheHeaderM/>
        {/*  */}
        <label className={ css.label_title }>签字用好签</label>
        <label className={ css.label_desc }>面向企业、个人的文件签署 & 管理平台</label>
        {/*  */}
        <button className={ css.downBtn }><a href="./download.html" style={{ color: 'inherit' }}>立即下载</a></button>
        {/*  */}
        <div className={ css.videoBox }>
          <img className={ css.poster } src={ ImgVideoBack }/>
          <img
            className={ css.play }
            src={ ImgVideoPlay }
            onClick={ this.onPlay }/>
          {/* <video poster ={ ImgVideoPoster } controls={true} autoPlay={true} src={ VideoHome }></video> */}
        </div>
        {/* part1 */}
        <div className={ css.part1 }>
          <span>为什么选择好签</span>
          <div className={ css.part1_percent }>
            <span>65%</span>
            <span>75%</span>
            <span>95%</span>
          </div>
          <div className={ css.part1_desc }>
            <span>降低签署成本</span>
            <span>签署流程提速</span>
            <span>存储安全性提升</span>
          </div>
        </div>
        {/* part2 */}
        <div className={ css.part2 }>
          <div className={ css.part2_title }>好签如何帮助企业降本增效</div>
          <div className={ css.part2_desc }>超过 100W+企业的安心选择</div>
        </div>
        {/* part3 */}
        <div
          className={ css.part3 }
          style={{ height: this.state.part3Collesped ? '400px' : 'auto' }}>
          {/* 1 */}
          <div className={ css.part3_item }>
            <img src={ImgPart31}/>
            <div>
              <div>太平洋建设</div>
              <div>建筑行业文件流程繁琐，周期长，效率低使用好签——随时随地快捷审批，安全高效</div>
            </div>
          </div>
          {/* 2 */}
          <div className={ css.part3_item }>
            <img style={{ visibility: 'hidden' }}/>
            <div>
              <div style={{ textAlign: 'right' }}>碧桂园</div>
              <div className={ css.clearBorder }>地产行业文件数量大，异地办公多使用好签——随时随地移动签到，方便快捷</div>
            </div>
            <img src={ImgPart32}/>
          </div>
          {/* 3 */}
          <div className={ css.part3_item }>
            <img src={ImgPart33}/>
            <div>
              <div>永辉超市</div>
              <div>零售连锁门店分布广，异地签署邮寄成本高使用好签——随时随地远程签署，降低成本</div>
            </div>
          </div>
          {/* 4 */}
          <div className={ css.part3_item }>
            <img style={{ visibility: 'hidden' }}/>
            <div>
              <div style={{ textAlign: 'right' }}>晋桦豹胶轮车制造</div>
              <div className={ css.clearBorder }>制造企业很多环节要签字确认使用好签——文件即时传输，手写签字，责任到人，提高效率</div>
            </div>
            <img src={ImgPart34}/>
          </div>
          {/* 5 */}
          <div className={ css.part3_item }>
            <img src={ImgPart35}/>
            <div>
              <div>国大教育集团</div>
              <div>教育行业学生分布广使用好签——微信即可快捷完成签署确认。用户无须到场也可签署，师生体验更好。</div>
            </div>
          </div>
          {/* 6 */}
          <div className={ css.part3_item }>
            <img style={{ visibility: 'hidden' }}/>
            <div>
              <div style={{ textAlign: 'right' }}>迎驾酒业</div>
              <div className={ css.clearBorder }>销售人员出差频繁，传统报销周期长，审批慢使用好签——在外及时申报，便捷报销，审批高效</div>
            </div>
            <img src={ImgPart36}/>
          </div>
          {/* 遮罩 */}
          { this.state.part3Collesped && <div className={ css.part3_linner }></div> }
          {/* end */}
        </div>
        {/* 展开 折叠 */}
        <div
          className={ css.part4 }
          onClick={() => this.setState({ part3Collesped: !this.state.part3Collesped })}>
          <span>{ this.state.part3Collesped ? '查看更多' : '收起' }</span>
          <DoubleRightOutlined
            style={{ fontSize: 16, transform: `rotate(${this.state.part3Collesped ? 90 : -90}deg)` }}/>
        </div>
        {/* part5 */}
        <div className={ css.part5 } style={{ padding: '0 25px' }}>
          <img src={ImgPart5} style={{ width: '100%', height: 'auto' }}/>
        </div>
        {/* part6 */}
        <div className={ css.part6 }>
          <img src={ ImgPart6 } className={ css.part6_back }/>
          <div>
            <span>签字用好签</span>
            <div><a href="./download.html" style={{ color: 'inherit' }}>立即下载</a></div>
          </div>
        </div>
        {/* 脚 */}
        <TheFooter/>
        {/* 视频弹窗 */}
        <Modal
          title="签字用好签"
          visible={this.state.audioModalVisible}
          footer={null}
          bodyStyle={{ padding: 10 }}
          maskClosable={ false }
          destroyOnClose={true}
          onCancel={() => { this.setState({ audioModalVisible: false }) }}>
          {/* <img/> */}
          <video poster={ ImgVideoBack } controls={true} autoPlay={true} style={{ width: '100%', height: '100%' }} src={ this.state.audioModalVisible ? UrlVideo : '' }/>
        </Modal>
        {/* end */}
      </div>
    </div>
  }
}
